<template>
  <div class="flex-center" :style="{ backgroundColor: bgColor }">
    <div class="empty-img">
      <img
        src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/empty.png"
        :style="{ height: imgH + 'rpx', width: imgW + 'px' }"
      />
    </div>
    <div class="empty-text" :style="{ fontSize: fontSize + 'px', color: fontColor }" v-html="text" >

    </div>
  </div>
</template>

<script>
export default {
  name: 'emptyPage',
  props: {
    text: {
      type: String,
      default: '此处空空如也',
    },
    fontSize: {
      type: String,
      default: '14',
    },
    fontColor: {
      type: String,
      default: '#6C727A',
    },
    bgColor: {
      type: String,
      default: '',
    },
    imgH: {
      type: String,
      default: '160',
    },
    imgW: {
      type: String,
      default: '160',
    },
    topH: {
      type: String,
      default: '0',
    },
  },
};
</script>

<style scoped >
.flex-center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
}
/* .empty-text text {
  font-weight: 500;
} */
.empty-text {
  text-align: center;
  white-space: pre-line;
}
</style>
