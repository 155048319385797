<template>
    <van-popup v-model="useShow" round position="bottom" safe-area-inset-bottom>
        <div class="pop-box-bg">
            <div class="gift-tips">
                礼品卡使用说明
                <van-icon
                    name="cross"
                    class="close"
                    color="#333"
                    @click="useShow = false"
                />
            </div>
            <div class="headline">
                <img
                    class="round-sty"
                    src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/round.png"
                />
                <span class="round-title">使用范围:</span>
            </div>
            <div class="contentline">
                <div class="range">
                    <div
                        class="rangeitem"
                        v-for="item in useBeans"
                        :key="item.type"
                    >
                        <div class="rangepic">
                            <img :src="geturl(item.type)" />
                            <div class="rangetip" v-if="item.hasPart">
                                <div class="ts">部分</div>
                            </div>
                        </div>
                        <div class="rangetitle">{{ item.name }}</div>
                    </div>
                </div>
                <div
                    class="rangeremark preline"
                    v-if="useBeanRemark"
                    v-html="useBeanRemark"
                ></div>
            </div>
            <div class="headline">
                <img
                    class="round-sty"
                    src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/round.png"
                />
                <span class="round-title">使用门店:</span>
            </div>
            <div class="contentline max3 preline" v-html="useVenue"></div>
            <template v-if="canNotSuperpose">
                <div class="headline">
                    <img
                        class="round-sty"
                        src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/round.png"
                    />
                    <span class="round-title">使用限制:</span>
                </div>
                <div
                    class="contentline max3 preline"
                    v-html="canNotSuperpose"
                ></div>
            </template>
            <template v-if="useTips">
                <div class="headline">
                    <img
                        class="round-sty"
                        src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/round.png"
                    />
                    <span class="round-title">使用说明:</span>
                </div>
                <div class="contentline preline" v-html="useTips"></div>
            </template>
            <div class="know" @click="useShow = false">我知道了</div>
        </div>
    </van-popup>
</template>

<script>
// 礼品卡使用说明
export default {
    name: 'instructions',
    data() {
        return {
            useShow: false,
            useVenue: '',
            useBeans: [],
            canNotSuperpose: '',
            useTips: '',
            useBeanRemark: '',
        };
    },
    methods: {
        geturl(type) {
            switch (type) {
                case '1':
                    return 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/syfw_one.png';
                case '2':
                    return 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/syfw_five.png';
                case '3':
                    return 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/syfw_three.png';
                case '4':
                    return 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/syfw_two.png';
                case '5':
                    return 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/syfw_four.png';
                case '6':
                    return 'https://img.chaolu.com.cn/MINI/mini-camp/icon/used5.png';
            }
        },
        getUsedList(cardId) {
            if (this.cantclick) {
                return;
            }
            this.cantclick = true;
            this.$axios
                .post(this.baseURLApp + '/cd/key/query/getCardUseDirection', {
                    //
                    cardId,
                    userId: this.$store.state.userId,
                    token: this.$store.state.token,
                })
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    this.setData(data);
                    this.cantclick = false;
                })
                .catch(() => {
                    this.cantclick = false;
                });
        },
        setData(data) {
            this.useVenue = data.useVenueTitle;
            this.useBeans = data.useBeans;
            this.canNotSuperpose = data.canNotSuperpose;
            this.useTips = data.useRemarks;
            this.useBeanRemark = data.useBeanRemark;
            this.useShow = true;
        },
    },
};
</script>

<style lang="less" scoped>
.pop-box-bg {
    background: url('https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/syfw_bg.png')
        no-repeat top center;
    background-size: 100% auto;
    height: auto;
    padding: 0 30px 30px;
    color: #333;
    max-height: 90vh;
    overflow: auto;
    .gift-tips {
        font-size: 34px;
        padding: 40px 0 0;
        font-weight: bold;
        text-align: center;
        position: relative;
        .close {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .headline {
        display: flex;
        align-items: center;
        margin: 40px 0 16px;
        .round-sty {
            width: 24px;
            height: 24px;
        }
        .round-title {
            font-size: 26px;
            font-weight: bold;
            margin-left: 10px;
        }
    }
    .contentline {
        padding: 0 35px;
        font-size: 24px;
    }
    .range {
        display: flex;
        flex-wrap: wrap;
        margin: 40px 0 0;
        .rangeitem {
            margin-right: 54px;
            &:last-child{
                margin-right: 0;
            }
            .rangepic {
                width: 80px;
                height: 80px;
                position: relative;
                margin-bottom: 10px;
                img {
                    width: 100%;
                    height: 100%;
                }
                .rangetip {
                    position: absolute;
                    right: 0;
                    top: 0;
                    border-radius: 4px;
                    width: 40px;
                    line-height: 20px;
                    background: #ed5c42;
                    text-align: center;
                    color: #fff;
                    white-space: nowrap;
                    text-indent: -3px;
                    .ts {
                        transform: scale(0.65);
                    }
                }
            }
            .rangetitle {
                text-align: center;
            }
        }
    }
    .rangeremark {
        margin-top: 50px;
        max-height: 220px;
        overflow: auto;
        line-height: 38px;
        -webkit-overflow-scrolling: touch;
    }
    .max3 {
        max-height: 94px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
    .know {
        border-radius: 40px;
        background: #fdb928;
        color: #fff;
        text-align: center;
        line-height: 88px;
        margin-top: 50px;
    }
    .preline {
        white-space: pre-line;
    }
}
</style>
